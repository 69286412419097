import * as strings from 'VistoWebPartStrings';
import * as React from 'react';
import { PrimaryButton, DefaultButton, Text, Dialog, IDialogContentProps, DialogType, Stack, FocusZone } from '@fluentui/react';

import { PlanWizardStartPage } from './PlanWizardStartPage';
import { PlanWizardCreateNewPage } from './PlanWizardCreateNewPage';
import { PlanWizardProgressPage } from './PlanWizardProgressPage';
import { PlanWizardOpenExistingPage } from './PlanWizardOpenExistingPage';

import { IExecutableAction } from 'services/IExecutableAction';
import { INotify, INotification } from 'services/Notify';
import { PlanDataService, PlanVersion } from 'services/PlanDataService';
import { SharePointConfigurationService } from 'services/SharePointConfigurationService';
import { StorageService } from 'services/StorageService';
import { TextService } from 'services/TextService';
import { IVistoPlan } from 'sp';
import { trackClient } from 'shared/clientTelemetry';
import { makeGuidString } from 'shared/guid';
import { defaultModalProps } from 'dialogs/common';
import { PlanSettingsService } from 'services/PlanSettingsService';
import { PlanWizardTrigger, PlanWizardPage, PlanWizardOperation, PlanWizardCreateNewOperation, PlanWizardOpenExistingOperation } from './PlanWizardTypes';
import { PlanWizardStoragePage } from './PlanWizardStoragePage';
import { LocalStorageService } from 'services/LocalStorageService';
import { EnvContext } from 'services/EnvContext';
import { UrlService } from 'shared/urlService';
import { ITemplate } from 'services/ITemplate';
import { PlanTemplateService } from 'services/PlanTemplateService';

export enum PlanStorage {
  Database,
  TeamSite,
  Local
}

export function PlanWizard(props: {
  isPopup: boolean;
  siteUrl: string;

  planId: string;

  planName: string;
  trigger: PlanWizardTrigger;

  onDismiss: (changed: boolean, planId?: string, planName?: string) => void;
}) {

  const { hostKind, defaultFolderRelativeUrl } = React.useContext(EnvContext);

  const [planName, setPlanName] = React.useState(props.planName);
  const [planLanguage, _setPlanLanguage] = React.useState(TextService.uiLanguage);
  const setPlanLanguage = (language: string) => {
    const supportedLanguage = TextService.setUiLanguage(language);
    _setPlanLanguage(supportedLanguage);
  };

  const [createNewOperation, setCreateNewOperation] = React.useState<PlanWizardCreateNewOperation>('CreateNewBlank');
  const [openExistingOperation, setOpenExistingOperation] = React.useState<PlanWizardOpenExistingOperation>('SelectFile');

  const defaultStorage = hostKind === 'TeamsDesktop' || hostKind === 'TeamsMobile' || hostKind === 'TeamsWeb'
    ? PlanStorage.TeamSite
    : PlanStorage.Local;

  const [storage, setStorage] = React.useState<PlanStorage>(defaultStorage);

  const [planId, setPlanId] = React.useState(props.planId);

  const defaultOperation =
    (props.trigger === 'SpfxConfigurationButton' || !props.siteUrl)
      ? PlanWizardOperation.OpenExisting
      : props.trigger === 'UpgradePlaceholder'
        ? PlanWizardOperation.Upgrade
        : PlanWizardOperation.CreateNew;
  const [operation, setOperation] = React.useState<PlanWizardOperation>(defaultOperation);

  const [actions, setActions] = React.useState<IExecutableAction[]>([]);
  const [currentProgressActionIndex, setCurrentProgressActionIndex] = React.useState<number>();

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [statusText, setStatusText] = React.useState('');

  const executed =
    TextService.isValidNumber(currentProgressActionIndex)  && currentProgressActionIndex === actions.length || actions.length === 0;

  const [existingPlans, setExistingPlans] = React.useState<IVistoPlan[]>([]);
  React.useEffect(() => {
    StorageService.get(props.siteUrl).getPlanItems(props.siteUrl, ['planId', 'name']).then(result => {
      setExistingPlans(result);
    });
  }, [props.siteUrl]);

  const getPlanNameErrorMessage = (newPlanName: string): string => {
    if (existingPlans.some(p => p.name === newPlanName?.trim()))
      return TextService.format(strings.PlanWizard_NameNotUnique);
  };

  const pageInfo = {

    [PlanWizardPage.start]: {
      subText: TextService.format(strings.PlanWizardPageStart_SubText),
      backDisabled: () => true,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => false,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [PlanWizardPage.createNew]: {
      subText: TextService.format(strings.PlanWizardPageCreate_SubText),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !planId || !planName || (createNewOperation === 'CreateNewFromFile' && !importedJson) || !!getPlanNameErrorMessage(planName),
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },
    [PlanWizardPage.progress]: {
      subText: TextService.format(strings.PlanWizard_ConfigurePlan),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => isProcessing,
      nextLabel: () => executed ? TextService.format(strings.PlanWizard_Close) : TextService.format(strings.PlanWizard_Configure),
    },
    [PlanWizardPage.storage]: {
      subText: TextService.format(strings.PlanWizard_SelectStorage),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => false,
      nextLabel: () => TextService.format(strings.PlanWizard_Next),
    },

    [PlanWizardPage.openExisting]: {
      subText: TextService.format(strings.PlanWizardPageSelect_SubText),
      backDisabled: () => false,
      backLabel: () => TextService.format(strings.PlanWizard_Back),
      nextDisabled: () => !planId,
      nextLabel: () => TextService.format(strings.PlanWIzard_Finish),
    },
  };

  const [currentPageId, setCurrentPageId] = React.useState<PlanWizardPage>(PlanWizardPage.start);

  const [importedJson, setImportedJson] = React.useState<IVistoPlan>(null);
  const [importedFileName, setImportedFileName] = React.useState('');

  const getImportedPlan = () => {

    const importedSettings = {
      ...PlanSettingsService.getPlanSettings(importedJson),
      language: planLanguage
    };

    const plan: IVistoPlan = {
      ...importedJson,
      siteUrl: props.siteUrl,
      name: planName,
      planId: importedJson.name === planName ? importedJson.planId : planId,
      settingsJson: JSON.stringify(importedSettings)
    };
    return plan;
  };

  const prepareBrowserActions = (templatePlan: IVistoPlan, notify: INotify) => {
    return LocalStorageService.generateActions(templatePlan);
  };

  const prepareSharePointActions = (templatePlan: IVistoPlan, notify: INotify) => {
    const createPages = props.trigger === 'SpfxSiteHeader';
    return SharePointConfigurationService.generateActions(props.siteUrl, templatePlan, createPages, notify);
  };

  const [templates, setTemplates] = React.useState<ITemplate[]>([]);
  React.useEffect(() => {
    setTimeout(() => setTemplates(PlanTemplateService.getTemplates()), 0);
  },  [planLanguage]);

  const getNewTemplatePlan = (templateId: string): IVistoPlan => {
    const template = templates.find(x => x.id === templateId);
    const result = PlanTemplateService.createTemplatePlan({ ...template, name: planName });
    return {
      ...result,
      planId,
      planName,
      planVersion: PlanVersion.current,
      siteUrl: props.siteUrl,
    }
  }

  const [orgTemplates, setOrgTemplates] = React.useState<ITemplate[]>([]);

  React.useEffect(() => {
      const absoluteFolderUrl = UrlService.combine(UrlService.getOrigin(props.siteUrl), defaultFolderRelativeUrl);
      const ss = StorageService.get(absoluteFolderUrl);
      ss.getTemplates(absoluteFolderUrl).then(items => {
        
      for (let i = 0; i < items.length; ++i) {
        const item = items[i];
        ss.getFile(item.id).then(blob => {
          blob.text().then(json => {
            const updated: ITemplate = {...item, planJson: json };
            setOrgTemplates(x => x.map((v, j) => i === j ? updated : v))
          })
        })
      }

      setOrgTemplates(items);
    })
  }, []);

  const getNewOrgTemplatePlan = (templateId: string): IVistoPlan => {
    const template = orgTemplates.find(x => x.id === templateId);
    const result = PlanTemplateService.createTemplatePlan({ ...template, name: planName });
    return {
      ...result,
      planId,
      planName,
      planVersion: PlanVersion.current,
      siteUrl: props.siteUrl,
    }
  }

  const [selectedTemplateId, setSelectedTemplateId] = React.useState('blank');
  const [selectedOrgTemplateId, setSelectedOrgTemplateId] = React.useState(null);

  const prepareActions = (generate: (templatePlan: IVistoPlan, notify: INotify) => Promise<IExecutableAction[]>) => {
    setCurrentProgressActionIndex(undefined);
    setActions([]);
    setStatusText(TextService.format(strings.PlanWizard_AnalyzingSite));
    setIsProcessing(true);

    const templatePlan: IVistoPlan = (operation === PlanWizardOperation.CreateNew && createNewOperation === 'CreateNewFromFile')
      ? getImportedPlan()
      : (operation === PlanWizardOperation.CreateNew && createNewOperation === 'CreateNewOrg')
        ? getNewOrgTemplatePlan(selectedOrgTemplateId)
        : getNewTemplatePlan(selectedTemplateId);

    setPlanId(templatePlan.planId);

    const notify: INotify = {
      operation: () => { },
      plan: () => { },
      addNotification: (info: INotification) => { },
      clearNotifications: () => { },
      navigateTo: () => { }
    };

    generate(templatePlan, notify).then(result => {
      setIsProcessing(false);
      setActions(result);
      if (result.length) {
        setStatusText(TextService.format(strings.PlanWizard_ClickToProceed));
      } else {
        setStatusText(TextService.format(strings.PlanWizard_UpToDate));
      }
    }, err => {
      trackClient.error(`error generating actions for plan ${templatePlan.planId}`, err);
      setStatusText(TextService.format(strings.PlanWizard_ErrorAnalyzingSite));
      setIsProcessing(false);
    });
  };

  const executeActions = async () => {
    setIsProcessing(true);
    setCurrentProgressActionIndex(undefined);
    let p: IVistoPlan = null;
    let succeeded = true;
    for (let i = 0; succeeded && i < actions.length; ++i) {
      const action = actions[i];
      setStatusText(action.title);
      setCurrentProgressActionIndex(i);
      try {
        p = await action.execute(p);
      } catch (err) {
        succeeded = false;
        action.hasError = true;
        action.errorMessage = err?.message;
      }
    }
    setIsProcessing(false);
    setCurrentProgressActionIndex(actions.length);
  };

  const onWizardBack = () => {
    switch (currentPageId) {

      case PlanWizardPage.start:
        onDismiss(false);
        break;

      case PlanWizardPage.createNew:
      case PlanWizardPage.openExisting:
        setCurrentPageId(PlanWizardPage.start);
        break;

      case PlanWizardPage.storage:
        setCurrentPageId(PlanWizardPage.createNew);
        break;

      case PlanWizardPage.progress:
        switch (operation) {
          case PlanWizardOperation.CreateNew:
            setCurrentPageId(PlanWizardPage.storage);
            break;
          case PlanWizardOperation.OpenExisting:
            setCurrentPageId(PlanWizardPage.openExisting);
            break;
          case PlanWizardOperation.Upgrade:
            setCurrentPageId(PlanWizardPage.start);
            break;
          default:
            setCurrentPageId(PlanWizardPage.start);
            break;
        }
        break;
    }
  };

  const setPlanPageName = () => {
    const pageTitleInput: HTMLInputElement = document.querySelector(`[data-automation-id='propertyPanePageContent'] input[type='text']`);
    if (pageTitleInput) {
      pageTitleInput.value = planName;
      pageTitleInput.dispatchEvent(new Event('input', { bubbles: true }));
    }
  };

  const onDismiss = (changed: boolean, planId?: string, planName?: string) => {
    if (changed && operation === PlanWizardOperation.CreateNew) {
      const localStoragePlanKey = PlanDataService.getPlanLocalStorageKey(props.siteUrl, planId);
      trackClient.page(`PlanCreated_${selectedTemplateId}`);
      localStorage.setItem(localStoragePlanKey, 'created');
    }
    props.onDismiss(changed, planId, planName);
  }

  const onWizardNext = () => {
    switch (currentPageId) {
      case PlanWizardPage.start:
        switch (operation) {
          case PlanWizardOperation.CreateNew:
            setPlanId(makeGuidString());
            setCurrentPageId(PlanWizardPage.createNew);
            break;
          case PlanWizardOperation.OpenExisting:
            setPlanId('');
            setCurrentPageId(PlanWizardPage.openExisting);
            break;
          case PlanWizardOperation.Upgrade:
            setCurrentPageId(PlanWizardPage.progress);
            prepareActions(prepareSharePointActions);
            break;
        }
        break;

      case PlanWizardPage.createNew:
        setCurrentPageId(PlanWizardPage.storage);
        break;

      case PlanWizardPage.storage:
        setCurrentPageId(PlanWizardPage.progress);
        switch (storage) {
          case PlanStorage.TeamSite:
            prepareActions(prepareSharePointActions);
            break;
          case PlanStorage.Local:
            prepareActions(prepareBrowserActions);
            break;
        }
        break;

      case PlanWizardPage.progress:

        if (executed) {
          onDismiss(true, planId, planName);
          if (operation === PlanWizardOperation.CreateNew)
            setPlanPageName();
        } else {
          setStatusText(TextService.format(strings.PlanWizard_Processing));
          executeActions().then(() => {
            if (actions.some(a => a.hasError)) {
              setStatusText(TextService.format(strings.PlanWizard_CompletedError));
            } else {
              setStatusText(TextService.format(strings.PlanWizard_CompletedSuccess));
              onDismiss(true, planId, planName);
            }
          });
        }
        break;

      case PlanWizardPage.openExisting:
        onDismiss(true, planId, planName);
        break;
    }
  };

  const contentProps: IDialogContentProps = {
    type: DialogType.normal,
    title: TextService.format(strings.PlanWizard_WizardTitle),
  };

  const wizardPage =
    (currentPageId === PlanWizardPage.start)
      ? <PlanWizardStartPage
        siteUrl={props.siteUrl}
        operation={operation}
        setOperation={setOperation}
        trigger={props.trigger}
      />
      : (currentPageId === PlanWizardPage.createNew)
        ? <PlanWizardCreateNewPage
          siteUrl={props.siteUrl}
          getPlanNameErrorMessage={getPlanNameErrorMessage}
          planName={planName}
          setPlanName={setPlanName}
          planLanguage={planLanguage}
          setPlanLanguage={setPlanLanguage}
          createNewOperation={createNewOperation}
          setCreateNewOperation={setCreateNewOperation}
          planJson={importedJson}
          setPlanJson={setImportedJson}
          importedFileName={importedFileName}
          setImportedFileName={setImportedFileName}
          templates={templates}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          orgTemplates={orgTemplates}
          selectedOrgTemplateId={selectedOrgTemplateId}
          setSelectedOrgTemplateId={setSelectedOrgTemplateId}
        />
        : (currentPageId === PlanWizardPage.progress)
          ? <PlanWizardProgressPage
            operation={operation}
            statusText={statusText}
            actions={actions}
            currentProgressActionIndex={currentProgressActionIndex}
          />
          : (currentPageId === PlanWizardPage.openExisting)
            ? <PlanWizardOpenExistingPage
              siteUrl={props.siteUrl}
              existingPlans={existingPlans}
              planId={planId}
              setPlanId={setPlanId}
              planName={planName}
              setPlanName={setPlanName}
              openExistingOperation={openExistingOperation}
              setOpenExistingOperation={setOpenExistingOperation}
            />
            : (currentPageId === PlanWizardPage.storage)
              ? <PlanWizardStoragePage
                storage={storage}
                setStorage={setStorage}
              />
              : null;

  const contents =
    <React.Fragment>
      <Stack style={{ minHeight: 300, overflow: 'auto' }} tokens={{ childrenGap: 'm', padding: 's1' }} grow>
        <Text variant='large'>{pageInfo[currentPageId].subText}</Text>
        {wizardPage}
      </Stack>
      <FocusZone>
        <Stack horizontal grow horizontalAlign={props.isPopup ? 'end' : 'start'} tokens={{ childrenGap: 's1' }}>
          {props.isPopup && <DefaultButton tabIndex={-1} text={TextService.format(strings.ButtonCancel)} onClick={() => onDismiss(false)} />}
          <DefaultButton tabIndex={-1} text={pageInfo[currentPageId].backLabel()} disabled={pageInfo[currentPageId].backDisabled()} onClick={onWizardBack} />
          <PrimaryButton text={pageInfo[currentPageId].nextLabel()} disabled={pageInfo[currentPageId].nextDisabled()} onClick={onWizardNext} />
        </Stack>
      </FocusZone>
    </React.Fragment>;

  return props.isPopup
    ? <Dialog minWidth={500} isBlocking={true} dialogContentProps={contentProps} modalProps={defaultModalProps} isOpen={true} onDismiss={() => onDismiss(false)}>
      {contents}
    </Dialog>
    : <Stack grow>
      {contents}
    </Stack>;
}
